import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Toggle from 'react-toggle';
import { Auth } from 'aws-amplify';
import { useApolloClient, gql } from '@apollo/client';

const Table = styled.table`
  width: 100%;

  td {
    padding: 10px 0px;
    vertical-align: middle;
  }
`;

const Container = styled.div`
  width: 100%;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  background-color: white;
`;

const GET_REWARDS = gql`
  query userRewards($userId: String!) {
    user_rewards(where: { userId: { _eq: $userId } }) {
      electricity_gas
      home_loan
      id
      internet_phone
      insurance
      method_app
      method_email
      method_phone
      method_sms
      r_e_a
    }
  }
`;

const ADD_REWARDS = gql`
  mutation addRewards($userId: String!) {
    insert_user_rewards(objects: { userId: $userId }) {
      returning {
        id
      }
    }
  }
`;

const UPDATE_REWARDS = gql`
  mutation updateRewards(
    $userId: String!
    $electricity_gas: Boolean!
    $home_loan: Boolean!
    $r_e_a: Boolean!
    $insurance: Boolean!
    $internet_phone: Boolean!
    $method_app: Boolean!
    $method_email: Boolean!
    $method_phone: Boolean!
    $method_sms: Boolean!
  ) {
    update_user_rewards(
      where: { userId: { _eq: $userId } }
      _set: {
        electricity_gas: $electricity_gas
        home_loan: $home_loan
        r_e_a: $r_e_a
        internet_phone: $internet_phone
        insurance: $insurance
        method_app: $method_app
        method_email: $method_email
        method_phone: $method_phone
        method_sms: $method_sms
      }
    ) {
      returning {
        id
      }
    }
  }
`;

const Rewards = () => {
  const client = useApolloClient();
  const [rewardsData, setRewardsData] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);

  const getCurrentUser = async () => {
    const user = await Auth.currentAuthenticatedUser();
    console.log({ user });
    setCurrentUser(user);

    //get rewards and create if needed
    const res = await client.query({
      query: GET_REWARDS,
      variables: { userId: user.username },
    });

    if (res.data?.user_rewards?.length === 0) {
      const insertResponse = await client.mutate({
        mutation: ADD_REWARDS,
        variables: { userId: user.username },
      });
      console.log({ insertResponse });
    } else {
      const {
        electricity_gas,
        home_loan,
        internet_phone,
        insurance,
        method_app,
        method_email,
        method_phone,
        method_sms,
        r_e_a,
      } = res.data.user_rewards[0];
      setRewardsData({
        electricity_gas,
        home_loan,
        internet_phone,
        insurance,
        method_app,
        method_email,
        method_phone,
        method_sms,
        r_e_a,
      });
    }
  };

  useEffect(() => {
    getCurrentUser();
  }, []);

  const handleToggle = (evt) => {
    console.log({ evt });
    const r = { ...rewardsData };
    r[evt.target.id] = evt.target.checked;
    setRewardsData(r);

    console.log(r);
    const updateResponse = client.mutate({
      mutation: UPDATE_REWARDS,
      variables: {
        userId: currentUser.username,
        electricity_gas: r['electricity_gas'],
        home_loan: r['home_loan'],
        insurance: r['insurance'],
        r_e_a: r['r_e_a'],
        internet_phone: r['internet_phone'],
        method_app: r['method_app'],
        method_email: r['method_email'],
        method_phone: r['method_phone'],
        method_sms: r['method_sms'],
      },
    });
  };

  return (
    <>
      <h4>Rewards</h4>
      <p>
        Welcome to your rewards! Here you can track your progress towards home
        unlocks and opt into receive better deals on key expenses related to
        your home.
      </p>
      <Container>
        <strong>What offers you are interested in?</strong>
        <br />
        <br />
        <Table>
          <tr>
            <td>Internet and phone</td>
            <td width='50px'>
              {' '}
              <Toggle
                id='internet_phone'
                checked={rewardsData?.internet_phone}
                onChange={handleToggle}
              />
            </td>
          </tr>
          <tr>
            <td>Electricity and gas</td>
            <td width='50px'>
              {' '}
              <Toggle
                id='electricity_gas'
                checked={rewardsData?.electricity_gas}
                onChange={handleToggle}
              />
            </td>
          </tr>
          <tr>
            <td>Insurance</td>
            <td width='50px'>
              {' '}
              <Toggle
                id='insurance'
                checked={rewardsData?.insurance}
                onChange={handleToggle}
              />
            </td>
          </tr>
          <tr>
            <td>Your home loan</td>
            <td width='50px'>
              {' '}
              <Toggle
                id='home_loan'
                checked={rewardsData?.home_loan}
                onChange={handleToggle}
              />
            </td>
          </tr>
          <tr>
            <td>Real estate agents</td>
            <td width='50px'>
              {' '}
              <Toggle
                id='r_e_a'
                checked={rewardsData?.r_e_a}
                onChange={handleToggle}
              />
            </td>
          </tr>
        </Table>
      </Container>
      <br />
      <Container>
        <strong>How would you like to receive these offers?</strong>
        <br />
        <br />
        <Table>
          <tr>
            <td>Phone call</td>
            <td width='50px'>
              {' '}
              <Toggle  id='method_phone'
                checked={rewardsData?.method_phone}
                onChange={handleToggle} />
            </td>
          </tr>
          <tr>
            <td>SMS</td>
            <td width='50px'>
              {' '}
              <Toggle  id='method_sms'
                checked={rewardsData?.method_sms}
                onChange={handleToggle}/>
            </td>
          </tr>
          <tr>
            <td>Email</td>
            <td width='50px'>
              {' '}
              <Toggle  id='method_email'
                checked={rewardsData?.method_email}
                onChange={handleToggle}/>
            </td>
          </tr>
          <tr>
            <td>Via this app</td>
            <td width='50px'>
              {' '}
              <Toggle  id='method_app'
                checked={rewardsData?.method_app}
                onChange={handleToggle} />
            </td>
          </tr>
        </Table>
      </Container>
    </>
  );
};

export default Rewards;
