import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Auth } from "aws-amplify";
import { Card, Form, Button, Row, Col } from "react-bootstrap";
import { useApolloClient } from "@apollo/client";
import addUser from "../Services/addUser";
import { Facebook, Google } from "react-bootstrap-icons";

const SocialButton = styled(Button)`
  width: 100%;
  margin-bottom: 10px;
`;

const ClaimProperty = ({ id }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const client = useApolloClient();

  const handleSubmit = (evt) => {
    evt.preventDefault();
    Auth.signUp({
      username: email,
      password,
      attributes: {
        email,
      },
    })
      .then(async (user) => {
        localStorage.setItem("CONFIRMATION_EMAIL", email);

        await addUser(client, user.userSub, email);
        window.location.href = id ? `/confirm-email/${id}` : "/confirm-email";
      })
      .catch((err) => {
        alert("Sign-up error!");
        console.log({ err });
      });
  };

  //   useEffect(() => {
  //     const ga = window.gapi && window.gapi.auth2 ?
  //         window.gapi.auth2.getAuthInstance() :
  //         null;

  //     if (!ga) createScript();
  // }, [])

  // const signInWithGoogle = () => {
  //     const ga = window.gapi.auth2.getAuthInstance();
  //     console.log({ga});
  //     ga.signIn().then(
  //         googleUser => {
  //             getAWSCredentials(googleUser);
  //         },
  //         error => {

  //             console.log(error);
  //         }
  //     );
  // }

  // const getAWSCredentials = async (googleUser) => {
  //   console.log({googleUser});
  //     const { id_token, expires_at } = googleUser.getAuthResponse();
  //     const profile = googleUser.getBasicProfile();
  //     let user = {
  //         username: profile.getEmail(),
  //         email: profile.getEmail(),
  //         name: profile.getName()
  //     };

  //     const credentials = await Auth.federatedSignIn(
  //         'google',
  //         { token: id_token, expires_at },
  //         user
  //     );

  //     console.log('credentials', credentials);
  //     window.location.href = id ? `/login/${id}` : "/login";
  // }

  // const createScript = () => {
  //     // load the Google SDK
  //     const script = document.createElement('script');
  //     script.src = 'https://apis.google.com/js/platform.js';
  //     script.async = true;
  //     script.onload = initGapi;
  //     document.body.appendChild(script);
  // }

  // const initGapi = () => {
  //     // init the Google SDK client
  //     console.log('initGapi');
  //     const g = window.gapi;
  //     g.load('auth2', function() {
  //         g.auth2.init({
  //             client_id: '818353851697-ffvlmkr6ppvh9jb71vi7ptjmq27or87f.apps.googleusercontent.com',
  //             // authorized scopes
  //             scope: 'email'
  //         }).then(function() { console.log('gapi loaded'); }, function(error) { console.log(error); });
  //         // g.client.init({
  //         //   clientId: `818353851697-ffvlmkr6ppvh9jb71vi7ptjmq27or87f.apps.googleusercontent.com`,
  //         //   scope: 'profile email openid',
  //         // });
  //     });
  //   }

  function signInWithFacebook() {
    //var oauth2Endpoint = "https://www.facebook.com/v14.0/dialog/oauth";
    var oauth2Endpoint = "https://propbook.auth.ap-southeast-2.amazoncognito.com/oauth2/authorize"

    // Create <form> element to submit parameters to OAuth 2.0 endpoint.
    var form = document.createElement("form");
    form.setAttribute("method", "GET"); // Send as a GET request.
    form.setAttribute("action", oauth2Endpoint);

    // Parameters to pass to OAuth 2.0 endpoint.
    var params = {
      client_id: "50sthnspf3ss9bd1roak73r96u",
      //redirect_uri: "http://localhost:3000/fb_callback.html",
      redirect_uri: "https://propertylogbook.com.au/oauth_callback.html",
      response_type: "token",
      scope: "email openid",
      identity_provider: 'Facebook',
      state: id
    };

    // Add form parameters as hidden input values.
    for (var p in params) {
      var input = document.createElement("input");
      input.setAttribute("type", "hidden");
      input.setAttribute("name", p);
      input.setAttribute("value", params[p]);
      form.appendChild(input);
    }

    // Add form to page and submit it to open the OAuth 2.0 endpoint.
    document.body.appendChild(form);
    form.submit();
  }

  function signInWithGoogle() {

    console.log({id});
    // Google's OAuth 2.0 endpoint for requesting an access token
    //var oauth2Endpoint = "https://accounts.google.com/o/oauth2/v2/auth";
    var oauth2Endpoint = "https://propbook.auth.ap-southeast-2.amazoncognito.com/oauth2/authorize"
    // Create <form> element to submit parameters to OAuth 2.0 endpoint.
    var form = document.createElement("form");
    form.setAttribute("method", "GET"); // Send as a GET request.
    form.setAttribute("action", oauth2Endpoint);

    // Parameters to pass to OAuth 2.0 endpoint.
    var params = {
      client_id: "50sthnspf3ss9bd1roak73r96u",
      //redirect_uri: "https://localhost:3000/oauth_callback.html",
      redirect_uri: "https://propertylogbook.com.au/oauth_callback.html",
      response_type: "token",
      scope: "profile email openid",
      identity_provider: 'Google',
      state: id
    };

    // Add form parameters as hidden input values.
    for (var p in params) {
      var input = document.createElement("input");
      input.setAttribute("type", "hidden");
      input.setAttribute("name", p);
      input.setAttribute("value", params[p]);
      form.appendChild(input);
    }

    // Add form to page and submit it to open the OAuth 2.0 endpoint.
    document.body.appendChild(form);
    form.submit();
  }

  return (
    <>
      {/* <Row>
            <Col>
              <Form.Group controlId="formAddress">
                <Form.Label>Property address</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Type address ..."
                  onChange={(evt) => setPropertyAddress(evt.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>What is your interest in the property?</Form.Label>
                <Form.Select
                  aria-label="What is your interest in the property?"
                  onChange={(evt) => setPropertyInterest(evt.target.value)}
                >
                  <option>Select</option>
                  <option value="own">I own and live in this property</option>
                  <option>I rent this property</option>
                  <option>I own but do not live in this property</option>
                  <option>I manage this property</option>
                  <option>I want to buy this property</option>
                  <option>I viewed this property</option>
                  <option>I am a neighbour</option>
                  <option>I service this property</option>
                  <option>Other=</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <br /> */}
      <Row>
        <Col>
          <h3>Claim this property</h3>
          <br />

          <Card>
            <Card.Body>
              <SocialButton
                style={{ backgroundColor: "red", borderColor: "red" }}
                size="sm"
                onClick={signInWithGoogle}
              >
                <Google size={12} /> Use Google
              </SocialButton>
              <br />
              {/* <SocialButton size="sm" onClick={signInWithFacebook}>
                <Facebook size={12} /> Use Facebook
              </SocialButton> */}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <br />
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <Form.Group controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                onChange={(evt) => setEmail(evt.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <Form.Group controlId="formPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="text"
                password="true"
                onChange={(evt) => setPassword(evt.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <Button type="submit">Submit</Button>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            Already have an account?{" "}
            <a href={id ? `/login/${id}` : "/login"}>Login here</a>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default ClaimProperty;
