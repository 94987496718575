import React, { useState, useEffect, useRef } from "react";
import Camera, { DEVICE, FACING_MODE, PLACEMENT } from "react-camera-ios";
import { Modal, Button, Form, ListGroup, Accordion } from "react-bootstrap";
import { useQuery, useMutation, gql, useApolloClient } from "@apollo/client";
import { Auth } from "aws-amplify";
import Storage from "@aws-amplify/storage";
import ReactTagInput from "@pathofdev/react-tag-input";
import {
  CameraFill as CameraIcon,
  CameraVideoFill,
  FileEarmarkFill,
  TrashFill,
  EyeFill,
} from "react-bootstrap-icons";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import PropertySelector from "../Components/PropertySelector";

// Styles
import "react-camera-ios/build/styles.css";
import "@pathofdev/react-tag-input/build/index.css";

const containerStyle = {
  display: "flex",
  position: "absolute",
  width: "100%",
  height: "calc(100vh -25px)",
  top: "0px",
  left: "0px",
  background: "black",
};

const GET_DOCUMENTS = gql`
  query MyDocumentsQuery($searchQuery: String!, $propertyId: uuid) {
    documents(
      where: {
        _and: [
          { propertyId: { _eq: $propertyId } }
          {
            _or: [
              { name: { _ilike: $searchQuery } }
              { category: { _ilike: $searchQuery } }
              { notes: { _ilike: $searchQuery } }
            ]
          }
        ]
      }
    ) {
      awsKey
      category
      id
      name
      notes
      tags
      userId
    }
  }
`;

const ADD_DOCUMENT = gql`
  mutation InsertDocumentMutation(
    $category: String
    $tags: jsonb
    $name: String!
    $awsKey: String!
    $userId: String!
    $notes: String
    $propertyId: uuid
  ) {
    insert_documents(
      objects: {
        category: $category
        tags: $tags
        name: $name
        awsKey: $awsKey
        userId: $userId
        notes: $notes
        propertyId: $propertyId
      }
    ) {
      returning {
        id
      }
    }
  }
`;

const DELETE_DOCUMENT = gql`
  mutation DeleteDocumentMutation($id: uuid!) {
    delete_documents(where: { id: { _eq: $id } }) {
      returning {
        id
      }
    }
  }
`;

const SetS3Config = (bucket, level) => {
  Storage.configure({
    bucket: bucket,
    level: level,
    region: "ap-southeast-2",
    identityPoolId: "ap-southeast-2:5d1a9dd5-9d4b-41ae-a8f1-472e852b1f40",
  });
};

function base64toBlob(base64Data, contentType) {
  contentType = contentType || "";
  var sliceSize = 1024;
  var byteCharacters = atob(base64Data);
  var bytesLength = byteCharacters.length;
  var slicesCount = Math.ceil(bytesLength / sliceSize);
  var byteArrays = new Array(slicesCount);

  for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    var begin = sliceIndex * sliceSize;
    var end = Math.min(begin + sliceSize, bytesLength);

    var bytes = new Array(end - begin);
    for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, { type: contentType });
}

const MobileCamera = () => {
  const navigate = useNavigate();
  const [documents, setDocuments] = useState([]);
  const [imageData, setImageData] = useState(null);
  const [showCameraModal, setShowCameraModal] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);

  const [fileType, setFileType] = useState(null);
  const [fileExtension, setFileExtension] = useState(null);

  const [imageFilename, setImageFilename] = useState("");
  const [imageCategory, setImageCategory] = useState("");
  const [imageTags, setImageTags] = useState([]);
  const [imageNotes, setImageNotes] = useState("");
  const [selectedProperty, setSelectedProperty] = useState(null);

  const [searchQuery, setSearchQuery] = useState("%");

  const [addDocument] = useMutation(ADD_DOCUMENT);
  const [deleteDocument] = useMutation(DELETE_DOCUMENT);

  const photoInputRef = useRef(null);
  const videoInputRef = useRef(null);
  const fileInputRef = useRef(null);

  const client = useApolloClient();

  const getCurrentUser = async () => {
//    const user = await Auth.currentUserInfo();
const user = await Auth.currentAuthenticatedUser();
    if (!user) {
      navigate("/login");
    }
  };

  useEffect(() => {
    getCurrentUser();
    getMyDocuments();
  }, []);

  useEffect(() => {
    getMyDocuments();
  }, [searchQuery, selectedProperty]);

  const getMyDocuments = async () => {
    if(selectedProperty){
    const result = await client.query({
      query: GET_DOCUMENTS,
      fetchPolicy: "network-only",
      variables: { searchQuery, propertyId: selectedProperty.value },
    });
    setDocuments(result.data.documents);
  }
  };

  const uploadImage = async (data) => {
    const user = await Auth.currentAuthenticatedUser();
    console.log({user})

    SetS3Config("propbook-test", "private");
    const fileName = `${new Date()
      .toISOString()
      .substring(0, 19)
      .replaceAll(":", "_")}.${fileExtension}`;
    Storage.put(
      `book/${fileName}`,
      base64toBlob(data.substring(data.indexOf(",") + 1), fileType),
      {
        metadata: {
          imageFileName: imageFilename,
          imageCategory: imageCategory,
          imageTags: imageTags.join("|"),
          imageNotes: imageNotes,
        },
      }
    )
      .then(async (result) => {
        await addDocument({
          variables: {
            name: imageFilename,
            awsKey: result.key,
            userId: user.attributes?.sub ? user.attributes.sub : user.username,
            category: imageCategory,
            tags: JSON.stringify(imageTags),
            notes: imageNotes,
            propertyId: selectedProperty ? selectedProperty.value : null,
          },
        });

        getMyDocuments();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getImageFromS3 = (key) => {
    SetS3Config("propbook-test", "private");
    Storage.get(key)
      .then((result) => {
        console.log({ result });
        //setImageUrl(result);
        window.location.href = result;
      })
      .catch((err) => console.log(err));
  };

  const deleteDocumentFromS3 = async (key, id) => {
    try{
      SetS3Config("propbook-test", "private");
      await Storage.remove(key);
      await deleteDocument({ variables: { id: id } });
      alert("Document deleted successfully");
      getMyDocuments();
    } catch (err) {
      console.log(err);
    }
  };

  const confirmDelete = (key, id) => {
    // eslint-disable-next-line no-restricted-globals
    const doDelete = confirm("Are you sure you want to delete this document?");
    if(doDelete){
      deleteDocumentFromS3(key, id);
    }
  };

  const getMetaData = (documents) => {
    const promises = documents.map(async (doc) => {
      const fullDoc = await Storage.get(doc.key, { download: true });
      return {
        key: doc.key,
        data: fullDoc,
      };
    });
    return Promise.all(promises);
  };

  const listImagesFromS3 = () => {
    SetS3Config("propbook-test", "private");
    Storage.list(`book/`)
      .then(async (result) => {
        const newDocuments = await getMetaData(result);
        console.log({ newDocuments });

        // const docs = result.map(doc => ({
        //   key: doc.key,
        //   meta: newDocuments
        // }))
        //setDocuments(newDocuments);
        //setDocumentsMeta(newDocuments)
      })
      .catch((err) => console.log(err));
  };

  const handleCloseCamera = () => setShowCameraModal(false);
  const handleCloseDetailModal = () => setShowDetailModal(false);

  const handleSearchChange = (evt) => {
    setSearchQuery(`%${evt.target.value}%`);
  };

  const categoryOptions = [
    { label: "Structural" },
    { label: "Electrical" },
    { label: "Plumbing" },
    { label: "Garden" },
    { label: "Walls" },
    { label: "Roof" },
    { label: "Windows" },
    { label: "Security" },
    { label: "Appliance" },
    { label: "Contents" },
    { label: "Other" },
  ];

  const handleNewCamera = (evt) => {
    
    const fileName = evt.target.files[0].name;
    const lastDot = fileName.lastIndexOf(".");

    const ext = fileName.substring(lastDot + 1);

    let reader = new FileReader();

    reader.addEventListener(
      "load",
      function () {
        setImageData(reader.result);
      },
      false
    );
    reader.readAsDataURL(evt.target.files[0]);
    setFileType(evt.target.files[0].type);
    setFileExtension(ext);

    setShowDetailModal(true);
  };

  return (
    <>
      <PropertySelector onChange={setSelectedProperty} />
      <br />
      <h4>Logbook</h4>
      <p>
        Your safe storage of architectural plans, receipts, supplier invoices,
        warranties etc.
      </p>
      {/* <Button
        style={{ width: "100%" }}
        onClick={() => photoInputRef.current.click()}
      >
        <CameraIcon /> Capture Photo
      </Button>
      <br />
      <Button
        style={{ width: "100%" }}
        onClick={() => videoInputRef.current.click()}
      >
        <CameraVideoFill /> Capture Video
      </Button>
      <br /> */}
      {/* <Button
        style={{ width: "100%" }}
        onClick={() => fileInputRef.current.click()}
      >
        <FileEarmarkFill /> Add Log Entry
      </Button> */}
      <Button
        style={{ width: "100%" }}
        onClick={() => setShowDetailModal(true)}
      >
        <FileEarmarkFill /> Attach a file
      </Button>

      <input
        type="file"
        id="imageFile"
        ref={photoInputRef}
        capture="environment"
        accept="image/*"
        onChange={handleNewCamera}
        hidden
      ></input>

      <input
        type="file"
        id="videoFile"
        ref={videoInputRef}
        capture="environment"
        accept="video/*"
        onChange={handleNewCamera}
        hidden
      ></input>

      <input
        type="file"
        id="fileFile"
        ref={fileInputRef}
        onChange={handleNewCamera}
        onClick={(evt) => evt.target.value=null}
        hidden
      ></input>
      <hr />
      <Form.Label>Search</Form.Label>
      <Form.Control
        type="text"
        placeholder="Search by name, category, tag ..."
        onChange={handleSearchChange}
      />
      <br />
      <Accordion>
        {documents.map((doc, i) => (
          <Accordion.Item eventKey={i}>
            <Accordion.Header>{doc.name}</Accordion.Header>
            <Accordion.Body>
              Category: {doc.category}
              <br />
              Tags: {JSON.parse(doc.tags).join(",")}
              <br />
              Notes: {doc.notes}
              <hr />
           
           
              <Button onClick={() => getImageFromS3(doc.awsKey)}>
                <EyeFill /> View
              </Button>{" "}
              <Button
                onClick={() => confirmDelete(doc.awsKey, doc.id)}
                variant="danger"
              >
                <TrashFill /> Delete
              </Button>
              </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
      <Modal show={showCameraModal} onHide={handleCloseCamera}>
        <Modal.Header closeButton>
          <Modal.Title>Quick Capture</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={containerStyle}>
            <Camera
              device={DEVICE.MOBILE}
              facingMode={FACING_MODE.ENVIRONMENT}
              placement={PLACEMENT.COVER}
              quality="1"
              onError={(error) => console.log(error)}
              onTakePhoto={(dataUrl) => {
                setImageData(dataUrl);
                setShowCameraModal(false);
                setShowDetailModal(true);
              }}
            />
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={showDetailModal} onHide={handleCloseDetailModal}>
        <Modal.Header closeButton>
          <Modal.Title>Quick Capture - Add details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label>Property*</Form.Label>
          <PropertySelector onChange={setSelectedProperty} />
          <br />
          <Form.Label>Name*</Form.Label>
          <Form.Control
            type="text"
            onChange={(evt) => setImageFilename(evt.target.value)}
          />
          <br />
          <Form.Label>Category</Form.Label>
          <Select
            placeholder="Select a category"
            options={categoryOptions}
            onChange={(val) => setImageCategory(val.label)}
          />
          <br />
          <Form.Label>Tags</Form.Label>
          <ReactTagInput
            tags={imageTags}
            onChange={(newTags) => setImageTags(newTags)}
          />
          <br />
          <Form.Label>Notes</Form.Label>

          <Form.Control
            as="textarea"
            onChange={(evt) => setImageNotes(evt.target.value)}
          />
          <br />
          <Button
            style={{ width: "100%" }}
            onClick={() => {
              uploadImage(imageData);
              setShowDetailModal(false);
              listImagesFromS3();
            }}
          >
            Save Details
          </Button>
        </Modal.Body>
      </Modal>
      {/* <button onClick={() => getImageFromS3()}>Get Image</button>
      <img src={imageUrl} width={300} /> */}
    </>
  );
};

export default MobileCamera;
